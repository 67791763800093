<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    :retain-focus="false"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2 ml-3 mt-4"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.PAGE") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>

            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.pages"
                v-model="formModel.status"
                :items="statuses.pages.page"
                :label="$t('FORMS.status')"
                item-value="key"
              >
                <template v-slot:selection="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
                <template v-slot:item="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                :rules="nameRules"
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :label="$t('FORM_INPUT_NAMES.title')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-if="
                  formModel.id &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.template_file != 'main_page'
                "
                v-model="formModel.translations[selectedLocale.lang].slug"
                :label="$t('FORMS.slug')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.slug']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.slug'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.pages"
                v-model="formModel.template_file"
                :items="pageTemplateCollection"
                :label="$t('MENU.PAGE_TEMPLATE')"
                item-text="name"
                item-value="slug"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <treeselect
                v-model="formModel.page_id"
                :clearable="clearable"
                :searchable="searchable"
                :options="computedParentPageCollection"
                :placeholder="$t('FORMS.parent')"
                :id="dynamicID"
                :noChildrenText="false"
                class="mt-4"
              >
              </treeselect>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="formModel.fictional"
                :label="$t('FORM_INPUT_NAMES.fictional')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.text'] = ''
                "
                :config="editorConfig"
              ></ckeditor>
              <small
                v-if="messages['translations.' + selectedLocale.lang + '.text']"
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".text"] }}
              </small>
            </v-col>
          </v-row>
          <!-- <v-row>
            [


              {
                  "customFieldName":"divider",
                  "config":{
                    "label":"FORMS.divider"
                  }
              }

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: $t('FORMS.seo_title') }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: $t('FORMS.seo_description'),
                }"
                customFieldName="seoDescription"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'ckeditor',
                  label: 'CKEDITOROS',
                  height: '550',
                  editorConfig: editorConfig,
                }"
                customFieldName="ckeditor"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .ckeditor
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'multi_checkbox',
                  label: 'MULTI checkbox',
                  items: [
                    { label: 'teszt egyes checkbox', value: 'test1' },
                    { label: 'teszt kettes checkbox', value: 'test2' },
                    { label: 'teszt harmadik checkbox', value: 'test3' },
                  ],
                }"
                customFieldName="checkbox"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .checkbox || []
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'multi_checkbox',
                  label: 'Simpla checkbo',
                  items: [{ label: 'Simpla checkbox teszt', value: 'true' }],
                }"
                customFieldName="simplaCHK"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .simplaCHK
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'multi_select',
                  multiple: true,
                  label: 'Multi select',
                  hint: 'Hint text',
                  items: ['elso select', 'alma', 'repa', 'korte'],
                }"
                customFieldName="multiSelect"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .multiSelect
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="radio"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .radio
                "
                :config="{
                  type: 'radio_group',
                  row: true,
                  items: [
                    { label: 'elso radio', value: 'elso' },
                    { label: 'kettes radio', value: 'masik' },
                    { label: 'harmadik radio', value: 'harom' },
                    { label: 'alma', value: 'alma' },
                    { label: 'leves', value: 'leves' },
                  ],
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="dataRangePicker"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .dataRangePicker || []
                "
                :config="{
                  type: 'date_range_picker',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="datePicker"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .datePicker || ''
                "
                :config="{
                  type: 'date_picker',
                  label: 'Select date',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="mediaSelector1"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .mediaSelector1 || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 5,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text'
                    },
                    { name: 'title', label: 'FORM_INPUT_NAMES.title', type: 'text' },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text'
                    },
                  ],
                  selectButtonText: $t('FORM_INPUT_NAMES.select_logo'),
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-row> -->

          <!-- <v-row v-for="field in templateJSON" :key="field.customFieldName">
            <v-col :class="field.config.initialColumnClass">
              <CustomFieldComponent
                v-if="
                  field.config.type &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :customFieldName="field.customFieldName"
                :config="field.config"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields[
                    field.customFieldName
                  ] ||
                  field.config.defaultValue ||
                  ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row> -->
          <v-row v-if="isTour">
            <div>
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'combo_box',
                  multiple: true,
                  label: 'Pincelátogatások',
                  itemText: 'name',
                  itemValue: 'id',
                  hint: 'Válassz pincelátogatást',
                  comboBoxItems: tours,
                }"
                customFieldName="tours"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .tours || []
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </div>
          </v-row>
          <v-row>
            <v-col
              v-for="field in templateJSON"
              :key="field.customFieldName"
              :class="getClass(field.config.initialColumnClass)"
            >
              <CustomFieldComponent
                v-if="
                  field.config.type &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields &&
                  field.customFieldName != 'divider'
                "
                :customFieldName="field.customFieldName"
                :config="field.config"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields[
                    field.customFieldName
                  ] ||
                  field.config.defaultValue ||
                  ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <v-divider v-if="field.customFieldName == 'divider'"></v-divider>
              <h3 v-if="field.customFieldName == 'divider'">
                {{ $t(field.config.label) }}
              </h3>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: 'FORMS.seo_title' }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORMS.seo_description',
                }"
                customFieldName="seoDescription"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8" md="8">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'text',
                      label: 'FORM_INPUT_NAMES.og_site_name',
                    }"
                    customFieldName="og_site_name"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_site_name || ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'text',
                      label: 'FORM_INPUT_NAMES.og_title',
                    }"
                    customFieldName="og_title"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_title || ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <CustomFieldComponent
                    v-if="formModel && formModel.custom_fields"
                    :config="{
                      type: 'multi_select',
                      multiple: false,
                      label: $t('FORM_INPUT_NAMES.og_type'),

                      items: og_types,
                    }"
                    customFieldName="og_type"
                    :value="formModel.custom_fields.og_type || 'website'"
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'textarea',
                      label: 'FORM_INPUT_NAMES.og_description',
                    }"
                    customFieldName="og_description"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_description || ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="og_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.og_image',
                  resolution: '1200x630',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Pages";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
// import template from "./templates/main_page.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const TRANSLATED_ATRIBUTES = ["name", "slug", "text"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "tours",
  "seoTitle",
  "seoDescription",
  "main_image",
  "template_file",
  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
  // "ckeditor",
  // "datePicker",
  // "mediaSelector1",
  // "dataRangePicker",
  // "checkbox",
  // "radio",
  // "multiSelect",
  // "simplaCHK",
];

export const INITIAL_CUSTOM_FIELDS = {
  og_type: "",
};

export const initialFormData = () => ({
  id: null,
  fictional: 0,
  page_id: null,
  status: 1,
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { CustomFieldComponent, Treeselect },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },

      templateJSON: [],
      og_types: ["website", "article"],
    };
  },
  computed: {
    ...mapGetters([
      "pageTemplateCollection",
      "pageCollection",
      // "productCollection",
    ]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            " " +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.PAGE");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    computedParentPageCollection() {
      let tree = this.createDataForTreeselect(
        this.pageCollection,
        null,
        this.formModel.id
      );
      return tree;
    },
    // tours() {
    //   return this.productCollection.filter(
    //     (item) =>
    //       item.product_category_id == process.env.VUE_APP_EGYEB_CATEGORY_ID
    //   );
    // },
    // isTour() {
    //   return (
    //     process.env.VUE_APP_TOURS_PAGE_ID == this.formModel.id &&
    //     this.formModel.id
    //   );
    // },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });

        this.setTemplate("[]");
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields)
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              this.setUpStreams();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },

    "formModel.template_file": function (newVal) {
      if (newVal)
        this.setTemplate(
          this.pageTemplateCollection.find((item) => item.slug == newVal).fields
        );
    },
  },
  methods: {
    ...mapActions(["fetchPageTemplate", "fetchPage"]),

    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      if (typeof model.page_id === "undefined") {
        model.page_id = null;
      }

      this.$refs.form.validate();

      if (this.formValid) {
        this.loader = true;
        this.resetErrorMessages();

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    setTemplate(template) {
      template = JSON.parse(template);
      this.initialTransaltedCustomFields = INITIAL_TRANSLATED_CUSTOM_FIELDS;
      template.forEach((field) => {
        this.initialTransaltedCustomFields.push(field.customFieldName);
      });

      this.templateJSON = template;
    },

    getClass(configClass) {
      if (!configClass)
        return "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12";
      return configClass;
    },

    createDataForTreeselect(layer, page_id = null, edited_id = null) {
      const vertex = new Map(),
        others = [];
      layer.forEach((item) => {
        if (item.page_id === page_id) {
          let isDisabled = false;
          if (
            edited_id &&
            (edited_id == item.id || edited_id == item.page_id)
          ) {
            isDisabled = true;
          }

          vertex.set(item.id, {
            id: item.id,
            label: this.$helpers.getTranslated(item.translations).name,
            isDisabled: isDisabled,
          });
        } else {
          others.push(item);
        }
      });
      for (const vertexId of vertex.keys()) {
        const children = this.createDataForTreeselect(
          others,
          vertexId,
          edited_id
        );
        if (children.length) {
          vertex.get(vertexId).children = children;
        }
      }
      return [...vertex.values()];
    },
    setUpStreams() {
      if (
        this.formModel &&
        this.formModel.translations[this.selectedLocale.lang] &&
        this.formModel.translations[this.selectedLocale.lang].custom_fields &&
        Object.keys(
          this.formModel.translations[this.selectedLocale.lang].custom_fields
        ).indexOf("external_streams") != -1
      ) {
        let old_streams =
          this.formModel.translations[this.selectedLocale.lang].custom_fields
            .external_streams;
        let streams = [];
        let now = new Date();
        old_streams.forEach((item) => {
          let valid_to = Date.parse(
            item.valid_to_date + " " + item.valid_to_time
          );
          if (isNaN(valid_to)) {
            streams.push(item);
          } else {
            if (valid_to >= now) {
              streams.push(item);
            }
          }
        });
        this.formModel.translations[
          this.selectedLocale.lang
        ].custom_fields.external_streams = streams;
        //console.log(streams);
      }
    },
  },

  mounted() {
    this.fetchPageTemplate();
    // this.fetchProduct();
    this.setTemplate("[]");
    this.setTranslatedAttributes();
  },
};
</script>
